/* eslint-disable no-undef */
import { AuthError, ValidationError, HttpError } from "../helpers/custom-errors";
import { isArrayWithLength, isAsyncSupported } from "../helpers/commons";
import log from 'loglevel';


const __BACKEND_API_PREFIX = "/app";

function appendPrefix(path) {
	return __BACKEND_API_PREFIX + path;
}

function sendHttpRequest(method, url, async, body) {
  
  let prefixedUrl = appendPrefix(url);

  //First check if fetch/async/await is supported
  if (window.fetch && async && isAsyncSupported()) {
	
	let fetchParams = {};
	fetchParams.method = method;
	fetchParams.accept = "application/json";
	fetchParams.body = body;

	return fetch(prefixedUrl, fetchParams)
   		.then(checkStatus);
  } else {

	
  //If fetch/await is not supported, we use XMLHttpRequest instead
  return new Promise(function(resolve, reject) {
    var xhr;
    var checkStatusXHR = function() {
      let error = new HttpError("GENERIC", xhr.status, `HTTP Error ${xhr.responseText}`);

	  try {

			let data;

			//if NOT an attachment, interpret response as json
        	if (xhr.getResponseHeader('Content-Disposition') && xhr.getResponseHeader('Content-Disposition').indexOf('attachment') > -1) {
				data = new Blob([xhr.response]);
        	} else {
				data = JSON.parse(xhr.responseText);
			}
		
			if (xhr.status >= 200 && xhr.status < 299) {
				resolve(data);
			} else if (xhr.status >= 400 && xhr.status < 499) { //Http Client Error
				//Check if code attribute has been properly set
				if (data.code) {
					//Check if AuthError
					if (data.code.indexOf("auth-") !== -1) {
						error = new AuthError(data.code, xhr.status, data.message);
					} else { 
						error = new ValidationError(data.code, xhr.status, data.detail, data.message);
					}
				} 
	    	} 

		} catch(err) {
			//Do nothing here. This block exists only to intercept json parse exception
			//Proper error handler should happen in block bellow
			
			log.error("backend-api.checkStatusXHR: " + err);
			
    	}

		reject(error);
	};

    xhr = new XMLHttpRequest();
    if (async) {
      xhr.onreadystatechange = function() {
        if (xhr.readyState !== 4) {
          return;
        }
        checkStatusXHR();
      };
    }
    xhr.open(method, prefixedUrl, async);
    xhr.send(body);
    if (!async) {
      checkStatusXHR();
    }
  });
}
}

async function checkStatus(response) {
	
	//Initially, we set default HttpError with generic error message
	let error = new HttpError("GENERIC", response.status, `HTTP Error ${response.statusText}`);
	
	// Then we to process/parse response body in json format
	// If it fails, a standard Error/Exception will be thrown and handled in the catch statement
	try {
		
		let data;

		//if NOT an attachment, interpret response as json
		
        if (response.headers.get('Content-Disposition') && response.headers.get('Content-Disposition').indexOf('attachment') > -1) {
			data = await response.blob();
        } else {
			data = await response.json();
		}

		//const jsonData = await response.json();
		
		if (response.ok) {
			return data;
		} else if (response.status >= 400 && response.status < 499) { //Http Client Error
			//Check if code attribute has been properly set
			if (data.code) {
				//Check if AuthError
				if (data.code.indexOf("auth-") !== -1) {
					error = new AuthError(data.code, response.status, data.message);
				} else { 
					error = new ValidationError(data.code, response.status, data.detail, data.message);
				}
			} 
	    } 

	} catch (err) {
		//Do nothing here. This block exists only to intercept json parse exception
		//Proper error handler should happen in block bellow
		
		log.error("backend-api.checkStatus: " + err);
	}
	
	//Finally Error is throw to be handled in upper level
	throw error;
}




function objectToFormData(obj, blackList) {
	var fd = new FormData();
	var formKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			formKey = property;
			fd.append(formKey, obj[property]);
		}
	}

	return fd;    
};

function objectToQueryParameters(obj, blackList) {
	var queryString = "";
	var filterKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			filterKey = property;
			if (queryString) queryString = queryString.concat("&");
			else queryString = queryString.concat("?");
			queryString = queryString.concat(filterKey, '=', encodeURIComponent(obj[property]));
		}
	}

	return queryString;    
};

function getConfig() {
   
   return fetch(appendPrefix('/config'), {
	  accept: "application/json"
   })
   .then(checkStatus);

   //Force Syncronous Request
   //return sendHttpRequest('GET', '/config', false);
}

function getBuildInfo() {
   
   return fetch(appendPrefix('/build/info'), {
	  accept: "application/json"
   })
   .then(checkStatus);
}

function getEnvironment() {
   
   return fetch(appendPrefix('/environment'), {
	  accept: "application/json"
   })
   .then(checkStatus);
}


function getUserAuthState() {
	  
	return fetch(appendPrefix('/auth'), {
		accept: "application/json"
	})
	.then(checkStatus);
	
	//return sendHttpRequest('GET', '/auth', true);
}

function updateLanguage(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/lang'), {
		method: 'post',
		body: form
	})
	.then(checkStatus);
}

function loginUser(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/login'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function logoutUser(csrfToken) {

	var form = new FormData();
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/logout'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function changeUserPassword(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	return fetch(appendPrefix('/pwd/change'), {
		method: 'PATCH',
		body: form
	})
	.then(checkStatus)
}

function sendUserPasswordResetEmail(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/pwd/recover'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function confirmUserPasswordReset(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/pwd/reset'), {
		method: 'PATCH',
		body: form
	})
   .then(checkStatus);
}

function verifyUserPasswordResetToken(data, csrfToken) {
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/pwd/token'), {
		method: 'post',
		body: form
	})
   .then(checkStatus);
}

function pageAccounts(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/accounts')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupAccount(id) {

	return fetch(appendPrefix('/accounts/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupAccountView(id) {

	return fetch(appendPrefix('/accounts/view/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createAccount(data, csrfToken) {
	
	var form = objectToFormData(data, ["preferences"]);
	form.append("csrfToken", csrfToken);

	if (data && isArrayWithLength(data.preferences)) {
		data.preferences.forEach((item) => {
			form.append("preferences[]", item);
		});
	}
	
	return fetch(appendPrefix('/accounts'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateAccount(id, data, csrfToken) {
		
	var form = objectToFormData(data, ["preferences"]);
	form.append("csrfToken", csrfToken);

	if (data && isArrayWithLength(data.preferences)) {
		data.preferences.forEach((item) => {
			form.append("preferences[]", item);
		});
	}
	
	return fetch(appendPrefix('/accounts/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function resetAccountAccessToken(id, csrfToken) {
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/accounts/token/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function importAccounts(data, csrfToken) {
	
	var form = objectToFormData(data, ["data"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.data)) {
		data.data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix('/accounts/import'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function findUsers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/users/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageUsers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/users')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupUser(id) {

	return fetch(appendPrefix('/users/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createUser(data, csrfToken) {
	
	var form = objectToFormData(data, ["roles", "permissions", "workgroups"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.roles)) {
		data.roles.forEach((item) => {
			form.append("roles[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.permissions)) {
		data.permissions.forEach((item) => {
			form.append("permissions[]", item);
		});
	}
	
	return fetch(appendPrefix('/users'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateUser(id, data, csrfToken) {
	
	var form = objectToFormData(data, ["roles", "permissions","workgroups"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.roles)) {
		data.roles.forEach((item) => {
			form.append("roles[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.permissions)) {
		data.permissions.forEach((item) => {
			form.append("permissions[]", item);
		});
	}
	
	return fetch(appendPrefix('/users/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateUserPassword(id, data, csrfToken) {
		
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/users/'+id+'/pwd'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateSelfPassword(data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/users/self/pwd'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}


function findAccounts(filter, isAdmin) {

	var path = (isAdmin ? '/accounts/all/admin' : '/accounts/all');
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function verifyAccountAccess(data) {

	var path = '/accounts/verify';
	var query = objectToQueryParameters(data);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupAccountStatementByAccessToken(data) {

	var path = '/accounts/statement';
	var query = objectToQueryParameters(data);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getNextAccountNo(csrfToken) {
	
	var path = '/accounts/next';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}


function findActivities(filter) {

	var path = '/activities/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageActivities(filter) {

	var path = '/activities';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageProducts(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/products')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findProducts(filter, isAdmin) {

	var path = (isAdmin ? '/products/all/admin' : '/products/all');
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupProduct(id) {

	return fetch(appendPrefix('/products/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createProduct(data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/products'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateProduct(id, data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/products/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function pageSuppliers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/suppliers')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findSuppliers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/suppliers/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function lookupSupplier(id) {

	return fetch(appendPrefix('/suppliers/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createSupplier(data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/suppliers'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateSupplier(id, data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/suppliers/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function resetSupplierAccessToken(id, csrfToken) {
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/suppliers/token/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function getNextSupplierNo(csrfToken) {
	
	var path = '/suppliers/next';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}
function pageOrders(filter) {

	var path = '/orders';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function findOrders(filter) {

	var path = '/orders/all';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getOrdersSummaryByProduct(filter) {

	var path = '/orders/products';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getOrdersSummaryByAccount(filter) {

	var path = '/orders/accounts';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findOrderSheets(filter) {

	var path = '/order-sheets/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function registerOrderSheetItems(data, csrfToken) {
	
	var path = '/order-sheets/register';
		
	var form = objectToFormData(data,["dates","accounts"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.dates)) {
		data.dates.forEach((item) => {
			form.append("dates[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.accounts)) {
		data.accounts.forEach((item) => {
			form.append("accounts[]", item);
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function lookupOrder(id) {

	return fetch(appendPrefix('/orders/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createOrder(data, isAdmin, csrfToken) {
	
	var path = (isAdmin ? '/orders/admin' : '/orders');
		
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateOrder(id, data, isAdmin, csrfToken) {
	
	//var path = (isAdmin ? '/orders/'+id+'admin' : '/orders/'+id);
	var path = '/orders/'+id;

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function registerOrder(id, data, csrfToken) {
	
	var path = '/orders/register/'+id;

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function deleteOrder(id, isAdmin, csrfToken) {

    var path = (isAdmin ? '/orders/'+id+'/admin' : '/orders/'+id);
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteMultipleOrders(data, csrfToken) {

	var path = '/orders/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function pageAccountTransactions(filter) {

	var path = '/account-transactions';
	var query = objectToQueryParameters(filter, ["types","tags"]);
	
	if (filter) {
		
		//Check if transaction types
	 	if (isArrayWithLength(filter.types)) {
			filter.types.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('types[]', '=', item);
			});
	 	}
	
		//Check if tags
		if (isArrayWithLength(filter.tags)) {
			filter.tags.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('tags[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findAccountTransactions(filter) {

	var path = '/account-transactions/all';
	var query = objectToQueryParameters(filter, ["types","tags"]);
	
	if (filter) {
		
		//Check if transaction types
	 	if (isArrayWithLength(filter.types)) {
			filter.types.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('types[]', '=', item);
			});
	 	}
	
		//Check if tags
		if (isArrayWithLength(filter.tags)) {
			filter.tags.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('tags[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupAccountTransaction(id) {

	var path = '/account-transactions/'+id;
	
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createAccountTransaction(data, csrfToken) {
	
	var path = '/account-transactions';
		
	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateAccountTransaction(id, data, csrfToken) {
	
	var path = '/account-transactions/'+id;

	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function deleteAccountTransaction(id, csrfToken) {

    var path = '/account-transactions/'+id;
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function saveMultipleAccountTransactions(data, csrfToken) {

	var path = '/account-transactions/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function deleteMultipleAccountTransactions(data, csrfToken) {

	var path = '/account-transactions/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function importAccountTransactions(data, csrfToken) {
	
	var path = '/account-transactions/import';
	
	var form = objectToFormData(data, ["data"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.data)) {
		data.data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function pageLedgers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/ledgers')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLedgers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/ledgers/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupLedger(id) {

	return fetch(appendPrefix('/ledgers/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLedger(data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/ledgers'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateLedger(id, data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/ledgers/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function pageAccountStatements(filter) {

	var path = '/account-statements';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findAccountStatements(filter) {

	var path = '/account-statements/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getAccountStatementsSummary(filter) {

	var path = '/account-statements/summary';
	var query = objectToQueryParameters(filter, ["tags"]);
	
	if (filter) {
	
		//Check if tags
		if (isArrayWithLength(filter.tags)) {
			filter.tags.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('tags[]', '=', item.id);
			});
	 	}
	 	
	 	
	
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageAccountStatementTransactionsByAccessToken(filter) {

	var path = '/account-statements/transactions';
	var query = objectToQueryParameters(filter, ["types"]);
	
	if (filter && isArrayWithLength(filter.types)) {
		filter.types.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('types[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findAccountTransactionOrdersByAccessToken(id, filter) {

	var path = '/account-statements/transactions/'+id+'/orders';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findAccountOrdersByAccessToken(filter) {

	var path = '/accounts/orders/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findAccountBarTabsByAccessToken(filter) {

	var path = '/accounts/bartabs/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findAccountBarTabs(id, filter) {

	var path = '/accounts/'+id+'/bartabs/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getAccountStatementInfo(id) {

	var path = '/accounts/'+id+'/statement';
	
	return fetch(appendPrefix(path), {
		accept: "application/json"
	 })
	.then(checkStatus)
}



function findCustomTags(filter) {

	var path = '/tags/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createCustomTag(data, csrfToken) {

    var path = '/tags';
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateCustomTag(id, data, csrfToken) {

    var path = '/tags/'+id;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteCustomTag(id, csrfToken) {

    var path = '/tags/'+id;
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function findSupplySchemes(filter) {

	var path = '/supply-schemes/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupSupplyScheme(productId, accountNo) {

	var path = '/supply-schemes/'+productId+"/"+accountNo;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createSupplyScheme(data, csrfToken) {

    var path = '/supply-schemes';
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateSupplyScheme(productId, accountNo, data, csrfToken) {

    var path = '/supply-schemes/'+productId+"/"+accountNo;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteSupplyScheme(productId, accountNo, csrfToken) {

    var path = '/supply-schemes/'+productId+"/"+accountNo;
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}


function pageSupplierOrders(filter) {

	var path = '/supplier-orders';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter){
	
		//products
		if(isArrayWithLength(filter.products)) {
			filter.products.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('products[]', '=', item.productId);
			});
		}
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function findSupplierOrders(filter) {

	var path = '/supplier-orders/all';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupSupplierOrder(id) {

	return fetch(appendPrefix('/supplier-orders/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createSupplierOrder(data, csrfToken) {
	
	var path = '/supplier-orders';
		
	var form = objectToFormData(data,["items"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.items)) {
		data.items.forEach((item) => {
			form.append("items[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateSupplierOrder(id, data, csrfToken) {
	
	var path = '/supplier-orders/'+id;

	var form = objectToFormData(data,["items"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.items)) {
		data.items.forEach((item) => {
			form.append("items[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function deleteSupplierOrder(id, csrfToken) {

    var path = '/supplier-orders/'+id;
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function registerSupplierOrderExpenseTransaction(id, data, csrfToken) {
	
	var path = '/supplier-orders/'+id+'/register';
	
	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function pageSupplierOrderItems(filter) {

	var path = '/supplier-orders/items';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findSupplierOrderItems(filter) {

	var path = '/supplier-orders/items/all';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageLedgerTransactions(filter) {

	var path = '/ledger-transactions';
	var query = objectToQueryParameters(filter, ["tags"]);
	
	if (filter) {
	
		//Check if tags
		if (isArrayWithLength(filter.tags)) {
			filter.tags.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('tags[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLedgerStatements(filter) {

	var path = '/ledger-statements/all';
	var query = objectToQueryParameters(filter, ["tags"]);
	
	if (filter) {
	
		//Check if tags
		if (isArrayWithLength(filter.tags)) {
			filter.tags.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('tags[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLedgerTransactions(filter) {

	var path = '/ledger-transactions/all';
	var query = objectToQueryParameters(filter, ["tags"]);
	
	if (filter) {
	
		//Check if tags
		if (isArrayWithLength(filter.tags)) {
			filter.tags.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('tags[]', '=', item.id);
			});
	 	}
	
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupLedgerTransaction(id) {

	var path = '/ledger-transactions/'+id;
	
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLedgerTransaction(data, csrfToken) {
	
	var path = '/ledger-transactions';
		
	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateLedgerTransaction(id, data, csrfToken) {
	
	var path = '/ledger-transactions/'+id;

	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function deleteLedgerTransaction(id, csrfToken) {

    var path = '/ledger-transactions/'+id;
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function saveMultipleLedgerTransactions(data, csrfToken) {

	var path = '/ledger-transactions/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function deleteMultipleLedgerTransactions(data, csrfToken) {

	var path = '/ledger-transactions/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function importLedgerTransactions(data, csrfToken) {
	
	var path = '/ledger-transactions/import';
	
	var form = objectToFormData(data, ["data"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.data)) {
		data.data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function pageInventoryCounts(filter) {

	var path = '/inventory-counts';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter){
	
		//products
		if(isArrayWithLength(filter.products)) {
			filter.products.forEach((item) => {

				if (query) query = query.concat("&");
				else query = query.concat("?");
			
				query = query.concat('products[]', '=', item.productId);
			});
		}
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function findInventoryCounts(filter) {

	var path = '/inventory-counts/all';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupInventoryCount(id) {

	return fetch(appendPrefix('/inventory-counts/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createInventoryCount(data, csrfToken) {
	
	var path = '/inventory-counts';
		
	var form = objectToFormData(data,["items"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.items)) {
		data.items.forEach((item) => {
			form.append("items[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateInventoryCount(id, data, csrfToken) {
	
	var path = '/inventory-counts/'+id;

	var form = objectToFormData(data,["items"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.items)) {
		data.items.forEach((item) => {
			form.append("items[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function deleteInventoryCount(id, csrfToken) {

    var path = '/inventory-counts/'+id;
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function pageInventoryCountItems(filter) {

	var path = '/inventory-counts/items';
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getPeriodicInventoryCountSummary(id1, id2, filter) {

	var path = '/inventory-counts/periodic/'+id1+'/'+id2;
	
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getContinuousInventoryCountSummary(id1, filter) {

	var path = '/inventory-counts/continuous/'+id1;
	
	var query = objectToQueryParameters(filter,["products"]);
	
	if (filter && isArrayWithLength(filter.products)) {
		filter.products.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('products[]', '=', item.productId);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageMessages(filter) {

	var path = '/messages';
	var query = objectToQueryParameters(filter,["status"]);
	
	if (filter && isArrayWithLength(filter.status)) {
		filter.status.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('status[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findMessages(filter) {

	var path = '/messages/all';
	var query = objectToQueryParameters(filter,["status"]);
	
	if (filter && isArrayWithLength(filter.status)) {
		filter.status.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('status[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}



function lookupMessage(id) {

	var path = '/messages/'+id;
		
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

async function sendMessage(data, csrfToken) {
	
	var path = '/messages';
		
	var form = objectToFormData(data,["contentVariables"]);
	form.append("csrfToken", csrfToken);
	
	if (data && data.contentVariables) {
		form.append("contentVariables", JSON.stringify(data.contentVariables));
	}
		
	const response = await fetch(appendPrefix(path), {
        method: 'post',
        accept: "application/json",
        body: form
    });
    return checkStatus(response);
}

function sendAccountStatement(data, csrfToken) {

	var path = '/accounts/statement';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function sendMultipleAccountStatements(data, csrfToken) {

	var path = '/accounts/statement/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function findAccountActivities(filter) {

	var path = '/account-activities/all';
	
	var query = objectToQueryParameters(filter, ["actions"]);
	
	if (filter && isArrayWithLength(filter.actions)) {
		filter.actions.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('actions[]', '=', item);
		});
	}
	
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageAccountActivities(filter) {

	var path = '/account-activities';
	var query = objectToQueryParameters(filter, ["actions"]);
	
	if (filter && isArrayWithLength(filter.actions)) {
		filter.actions.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('actions[]', '=', item);
		});
	}
	
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function syncronizeMessageData(id, csrfToken) {
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/messages/'+id+'/sync'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function getMessageContent(id) {
	
	var path = '/messages/'+id+'/content';
		
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getMessageContentMediaUrl(messageSid, mediaSid) {
	
	return appendPrefix('/messages/'+messageSid+'/media/'+mediaSid);
		
}

function getMessageContentMedia(messageSid, mediaSid) {
	
	var path = '/messages/'+id+'/content';
		
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


const API = { getConfig, getBuildInfo, getEnvironment, updateLanguage, getUserAuthState, loginUser, logoutUser, changeUserPassword,
		sendUserPasswordResetEmail, confirmUserPasswordReset, verifyUserPasswordResetToken,
		pageAccounts, findAccounts, lookupAccount, createAccount, updateAccount, resetAccountAccessToken,
		importAccounts, verifyAccountAccess, lookupAccountStatementByAccessToken, getNextAccountNo, lookupAccountView,
		pageProducts, findProducts, lookupProduct, createProduct, updateProduct, 
		pageSuppliers, findSuppliers, lookupSupplier, createSupplier, updateSupplier, resetSupplierAccessToken, getNextSupplierNo,
		pageUsers, findUsers, lookupUser, createUser, updateUser, updateUserPassword, updateSelfPassword,  
		findActivities, pageActivities,
		pageOrders, findOrders, lookupOrder, createOrder, updateOrder, deleteOrder, deleteMultipleOrders,
		findOrderSheets, registerOrderSheetItems, registerOrder,
		pageAccountTransactions, findAccountTransactions, lookupAccountTransaction, createAccountTransaction, updateAccountTransaction, deleteAccountTransaction, deleteMultipleAccountTransactions, importAccountTransactions,
		pageLedgers, findLedgers, lookupLedger, createLedger, updateLedger,
		pageAccountStatements, findAccountStatements, getAccountStatementsSummary, pageAccountStatementTransactionsByAccessToken, findAccountTransactionOrdersByAccessToken, findAccountOrdersByAccessToken, findAccountBarTabsByAccessToken,
		getAccountStatementInfo, saveMultipleAccountTransactions, findAccountBarTabs,
		getOrdersSummaryByProduct, getOrdersSummaryByAccount,
		findCustomTags, createCustomTag, updateCustomTag, deleteCustomTag,
		findSupplySchemes, lookupSupplyScheme, createSupplyScheme, updateSupplyScheme, deleteSupplyScheme,
		pageSupplierOrders, findSupplierOrders, lookupSupplierOrder, createSupplierOrder, updateSupplierOrder, deleteSupplierOrder, pageSupplierOrderItems, findSupplierOrderItems, registerSupplierOrderExpenseTransaction,
		pageLedgerTransactions, lookupLedgerTransaction, createLedgerTransaction, updateLedgerTransaction, deleteLedgerTransaction, deleteMultipleLedgerTransactions, findLedgerStatements,
		saveMultipleLedgerTransactions, findLedgerTransactions, importLedgerTransactions,
		pageInventoryCounts, findInventoryCounts, lookupInventoryCount, createInventoryCount, updateInventoryCount, deleteInventoryCount, pageInventoryCountItems, 
		getPeriodicInventoryCountSummary, getContinuousInventoryCountSummary,
		pageMessages, findMessages, sendMessage, lookupMessage, getMessageContent, getMessageContentMediaUrl, getMessageContentMedia, sendAccountStatement, sendMultipleAccountStatements, syncronizeMessageData,
		findAccountActivities, pageAccountActivities
};

export default API;
